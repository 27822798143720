<!--
 * @Description: 转班抵扣弹窗
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2022-03-30 15:56:47
 * @LastEditors: Seven
 * @LastEditTime: 2022-03-30 16:35:58
-->
<template>
  <HqModal
    :visible="modalShow"
    title="转班抵扣确认"
    @on-cancel="modalShow = false"
    width="676"
  >
    <div>
      <div class="top">转班抵扣金额为0元，售后订单转班后没有任何金额能进入转班订单，申请通过后将不可以变更抵扣金额，请确认是否继续提交申请？</div>
      <div class="mid">
        <div>转班抵扣</div>
        <div>售后订单抵扣到转班订单的金额，用于转班订单付款<br/>转班抵扣=售后订单已收金额-扣费金额</div>
      </div>
      <div class="bot">
        <!-- <img src="@/assets/image/discount.png" alt=""> -->
        <el-image 
          style="width: 616px; height: 306px"
          :src="require('@/assets/image/discount.png')" 
          :preview-src-list="[require('@/assets/image/discount.png')]">
        </el-image>
      </div>
    </div>
    <div slot="footer">
      <el-button @click="modalShow = false">返回修改</el-button>
      <el-button
        size="small"
        type="primary"
        @click="saveReserve"
      >确认提交</el-button>
    </div>
  </HqModal>
</template>
<script>
export default {
  data() {
    return {
      modalShow: false
    }
  },
  methods: {
    init() {
      this.modalShow = true
    },
    saveReserve() {
      this.modalShow = false
      this.$emit('saveDicount');
    }
  }
}
</script>
<style lang="scss" scoped>
  .top {
    font-size: 14px;
    color: #F54955;
    font-weight: bold;
  }

  .mid {
    display: flex;
    margin: 18px 0 12px 0;

    >div {
      &:first-child {
        color: #F54955;
        margin-right: 18px;
        line-height: 28px;
      }

      &:last-child {
        line-height: 28px;
      }
    }
  }

  .bot {
    width: 616px;
    height: 306px;
    // border: 1px solid #707070;
    border: 1px dashed #707070;
    cursor: pointer;
    box-sizing: content-box;
  }
</style>
