var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "HqModal",
    {
      attrs: { visible: _vm.modalShow, title: "转班抵扣确认", width: "676" },
      on: {
        "on-cancel": function($event) {
          _vm.modalShow = false
        }
      }
    },
    [
      _c("div", [
        _c("div", { staticClass: "top" }, [
          _vm._v(
            "转班抵扣金额为0元，售后订单转班后没有任何金额能进入转班订单，申请通过后将不可以变更抵扣金额，请确认是否继续提交申请？"
          )
        ]),
        _c("div", { staticClass: "mid" }, [
          _c("div", [_vm._v("转班抵扣")]),
          _c("div", [
            _vm._v("售后订单抵扣到转班订单的金额，用于转班订单付款"),
            _c("br"),
            _vm._v("转班抵扣=售后订单已收金额-扣费金额")
          ])
        ]),
        _c(
          "div",
          { staticClass: "bot" },
          [
            _c("el-image", {
              staticStyle: { width: "616px", height: "306px" },
              attrs: {
                src: require("@/assets/image/discount.png"),
                "preview-src-list": [require("@/assets/image/discount.png")]
              }
            })
          ],
          1
        )
      ]),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  _vm.modalShow = false
                }
              }
            },
            [_vm._v("返回修改")]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.saveReserve }
            },
            [_vm._v("确认提交")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }